
// tslint:disable-next-line:interface-name
export interface Stringable {
  toString(): string
}

/**
 * Test whether the item exists (i.e. is not null or undefined).
 *
 * @example
 *   const myArr: Array<string | null | undefined> = ['a', null, undefined, 'b']
 *   const filtered: string[] = myArr.filter(exists)
 */
export function exists<T>(item: T | null | undefined | false): item is T {
  return !!item
}

export function present<T>(value: T): value is Exclude<T, false | undefined | null | ''> {
  if (typeof value == 'string') {
    return value.length > 0
  }

  return !!value
}

export function assert<T>(value: T, key?: string, msg?: string): asserts value is Exclude<T, false | undefined | null | ''> {
  if (!present(value)) {
    throw new Error(msg || 'assert failed! Value was not present')
  }
}

/**
 * Returns a new array where duplicate values have been removed
 */
export function uniq<T>(arr: T[]): T[] {
  return arr.filter((item, index) => arr.indexOf(item) === index)
}

export function uniqBy<T>(arr: T[], keyOrFn: keyof T | ((item: T) => any)): T[] {
  const fn: (item: T) => any = typeof(keyOrFn) == 'function' ?
    keyOrFn :
    (i: T) => i[keyOrFn]

  return arr.filter((item, index) => {
    const search = fn(item)
    return arr.findIndex((i) => fn(i) == search) === index
  })
}

declare global {
  // tslint:disable-next-line: interface-name
  interface Array<T> {
    /**
     * Returns a new array where duplicate values have been removed
     */
    uniq(): T[]

    /**
     * Returns a new array where duplicate values have been removed, as selected
     * by the key function
     */
    uniqBy(keyOrFn: keyof T | ((item: T) => any)): T[]
  }
}

Array.prototype.uniq = function() {
  return uniq(this)
}
Array.prototype.uniqBy = function(keyOrFn) {
  return uniqBy(this, keyOrFn)
}
